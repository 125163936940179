import React, { useEffect, useMemo } from 'react';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../core/hooks/reduxHooks';
import { useParams } from 'react-router-dom';
import PriceCard from '../PackagePriceCard/PriceCard';
import styles from './_styles.module.scss';
import StripeContainer from './StripeContainer';
import { getSubscriptionPackages } from '../../../../redux/thunk/subscription';

export default function PaymentForm() {
  const { packages } = useAppSelector(
    state => state.subscriptionPackagesReducer
  );
  const { id } = useParams();
  const dispatch = useAppDispatch();

  const selectedPackage = useMemo(
    () =>
      packages.basic_packages?.find(pack => pack.package_id === id) ||
      packages.premium_packages?.find(pack => pack.package_id === id),
    [id, packages]
  );

  useEffect(() => {
    if (!selectedPackage) {
      dispatch(getSubscriptionPackages());
    }
  }, []);

  return (
    <div className={styles.payment}>
      {selectedPackage ? (
        <>
          <div className={styles.payment_package}>
            <PriceCard
              key={selectedPackage.package_id}
              package_id={selectedPackage.package_id}
              title={selectedPackage.name}
              price={selectedPackage.price}
              posting={selectedPackage.number_of_posting}
              shortlist={selectedPackage.number_of_shortlist}
              valid={selectedPackage.validity_days}
              disableBtn={true}
            />
          </div>

          <StripeContainer
            paymentIntentForm={{
              subscription: {
                quantity: 1,
                item_id: selectedPackage.package_id,
              },
            }}
          />
        </>
      ) : (
        <div />
      )}
    </div>
  );
}
