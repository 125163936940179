import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ISubscriptionBumpPackages,
  ISubscriptionPackages,
  ISubscriptionShortlistPackages,
} from '../../../core/models/models';

interface SubscriptionPackagesState {
  isLoading: boolean;
  error: string;
  packages: ISubscriptionPackages;
  bumpPackages: ISubscriptionBumpPackages[] | null;
  shortlistPackages: ISubscriptionBumpPackages[] | null;
  selectedAssignmentId: string;
  // selectedSubscriptionPackage: IPackage;
  selectedBumpPackage: ISubscriptionBumpPackages | null;
  selectedShortlistPackage: ISubscriptionShortlistPackages | null;
  purchaseType: 'package' | 'bump' | 'shortlist';
}

const initialState: SubscriptionPackagesState = {
  isLoading: false,
  error: '',
  packages: {
    basic_packages: null,
    premium_packages: null,
  },
  bumpPackages: null,
  shortlistPackages: null,
  selectedAssignmentId: '',
  selectedBumpPackage: {
    package_id: '',
    amount: 0,
    price: 0,
  },
  selectedShortlistPackage: {
    package_id: '',
    amount: 0,
    price: 0,
  },
  purchaseType: 'package',
};

export const subscriptionPackagesSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    setSubscriptionPackages: (
      state,
      { payload }: PayloadAction<ISubscriptionPackages>
    ) => {
      state.packages = payload;
    },
    setSubscriptionShortlistPackages: (
      state,
      { payload }: PayloadAction<ISubscriptionShortlistPackages[]>
    ) => {
      state.shortlistPackages = payload;
      state.selectedShortlistPackage = payload[0];
    },
    setSubscriptionBumpPackages: (
      state,
      { payload }: PayloadAction<ISubscriptionBumpPackages[]>
    ) => {
      state.bumpPackages = payload;
      state.selectedBumpPackage = payload[0];
    },
    setSelectedAssignmentId: (state, { payload }: PayloadAction<string>) => {
      state.selectedAssignmentId = payload;
    },
    setSelectedBumpPackage: (
      state,
      action: PayloadAction<{ amount: number }>
    ) => {
      state.selectedBumpPackage =
        state.bumpPackages?.find(p => {
          return p.amount === action.payload.amount;
        }) || initialState.selectedBumpPackage;
    },
    setSelectedShortlistPackage: (
      state,
      action: PayloadAction<{ amount: number }>
    ) => {
      state.selectedShortlistPackage =
        state.shortlistPackages?.find(p => {
          return p.amount === action.payload.amount;
        }) || initialState.selectedShortlistPackage;
    },
    clearSelectedPackages: state => {
      state.selectedBumpPackage = initialState.selectedBumpPackage;
      state.selectedShortlistPackage = initialState.selectedShortlistPackage;
      // state.selectedAssignmentId = initialState.selectedAssignmentId;
      state.purchaseType = 'package';
    },
    setPurchaseType: (
      state,
      { payload }: PayloadAction<'package' | 'bump' | 'shortlist'>
    ) => {
      state.purchaseType = payload;
    },
  },
});

export const {
  setSubscriptionPackages,
  setSubscriptionShortlistPackages,
  setSubscriptionBumpPackages,
  setSelectedAssignmentId,
  setSelectedShortlistPackage,
  setSelectedBumpPackage,
  clearSelectedPackages,
  setPurchaseType,
} = subscriptionPackagesSlice.actions;

export default subscriptionPackagesSlice.reducer;
