import React, { FC, useEffect, useMemo } from 'react';
import styles from './_styles.module.scss';
import StripeContainer from '../PaymentForm/StripeContainer';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../core/hooks/reduxHooks';
import { Navigate, useParams } from 'react-router-dom';
import PriceCardBumpShortlist from '../PriceCardBumpShortlist/PriceCardBumpShortlist';
import {
  setPurchaseType,
  setSelectedBumpPackage,
} from '../../../../redux/reducers/ui/SubscriptionPackagesSlice';
import { breakpointForJobsList } from '../../../../core/constants/variables';
import {
  getSubscriptionBumpPackages,
  getSubscriptionShortlistPackages,
} from '../../../../redux/thunk/subscription';
import { useRouter } from '../../../../core/hooks/useRouter';

const PaymentFormBumpShortlist: FC = () => {
  const {
    selectedBumpPackage,
    selectedShortlistPackage,
    selectedAssignmentId,
  } = useAppSelector(state => state.subscriptionPackagesReducer);

  const { id } = useParams();
  const { location } = useRouter();
  const dispatch = useAppDispatch();

  const paymentForm = useMemo(() => {
    const shortlist = selectedShortlistPackage?.package_id
      ? {
          quantity: selectedShortlistPackage.amount,
          item_id: selectedAssignmentId,
        }
      : null;

    const repost = selectedBumpPackage?.package_id
      ? {
          quantity: selectedBumpPackage.amount,
          item_id: selectedAssignmentId,
        }
      : null;

    return shortlist &&
      selectedAssignmentId &&
      id &&
      selectedShortlistPackage?.package_id === id
      ? { shortlist: shortlist }
      : repost &&
        selectedAssignmentId &&
        id &&
        selectedBumpPackage?.package_id === id
      ? { repost: repost }
      : null;
  }, [selectedBumpPackage, selectedShortlistPackage, selectedAssignmentId, id]);

  const selectedPackage = useMemo(() => {
    return selectedBumpPackage?.package_id === id
      ? selectedBumpPackage
      : selectedShortlistPackage?.package_id === id
      ? selectedShortlistPackage
      : null;
  }, [selectedBumpPackage, selectedShortlistPackage, selectedAssignmentId]);

  const selectedPackageType: 'bump' | 'shortlist' | null = useMemo(() => {
    return selectedBumpPackage?.package_id === id
      ? 'bump'
      : selectedShortlistPackage?.package_id === id
      ? 'shortlist'
      : null;
  }, [selectedBumpPackage, selectedShortlistPackage, selectedAssignmentId]);

  useEffect(() => {
    if (selectedBumpPackage?.package_id === id) {
      dispatch(setPurchaseType('bump'));
    }

    if (selectedShortlistPackage?.package_id === id) {
      dispatch(setPurchaseType('shortlist'));
    }
  }, [selectedBumpPackage, selectedShortlistPackage]);

  if (id && !selectedPackage && !selectedPackageType) {
    return <Navigate to={`/employer/price`} state={{ from: location }} />;
  }

  return (
    <div className={styles.payment}>
      {id && selectedPackage && selectedPackageType && (
        <PriceCardBumpShortlist
          id={selectedPackage.package_id}
          type={selectedPackageType}
          price={selectedPackage.price}
          assignmentId={selectedAssignmentId}
        />
      )}
      <div className={styles.payment_stripe}>
        {paymentForm && <StripeContainer paymentIntentForm={paymentForm} />}
      </div>
    </div>
  );
};

export default PaymentFormBumpShortlist;
