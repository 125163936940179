import React, { FC, useEffect } from 'react';
import styles from './_styles.module.scss';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../core/hooks/reduxHooks';
import OtherDetails from '../OtherDetails/OtherDetails';
import UIContainer from '../../../../containers/Container/Container';
import PackagesPriceCardList from './PackagesPriceCardList';
import { getSubscriptionPackages } from '../../../../redux/thunk/subscription';
import useWebContent from '../../../../core/hooks/useWebContent';

const PriceCardListPage: FC = () => {
  const { packages } = useAppSelector(
    state => state.subscriptionPackagesReducer
  );
  const dispatch = useAppDispatch();
  const [webContent] = useWebContent();

  useEffect(() => {
    dispatch(getSubscriptionPackages());
  }, []);

  return (
    <>
      <UIContainer>
        <div className={styles.price_list_wrap}>
          {packages.premium_packages ? (
            <div className={styles.packages}>
              {packages.basic_packages && (
                <h2 className={styles.title}>Premium Packages</h2>
              )}
              <PackagesPriceCardList packages={packages.premium_packages} />
            </div>
          ) : null}

          {packages.basic_packages ? (
            <div className={styles.packages}>
              <h2 className={styles.title}>Basic Packages</h2>
              <PackagesPriceCardList packages={packages.basic_packages} />
            </div>
          ) : (
            <div className={styles.packages}></div>
          )}
        </div>
      </UIContainer>
      <OtherDetails />
    </>
  );
};

export default PriceCardListPage;
